@import 'src/App.scss';

#PersData{
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px 25px;
    background: white;
    left: -100%;
    transition: 0.3s;
    height: 100%;
}
#PersData.Open{
    left: 0;
}

#PersDataNameBlock{
    padding: 25px 40px;
    background: $darkGreenSystem;
    border-radius: 15px;
    color:white;
}
#PersDataName{
    font-family: "Inter-Bold";
    font-size: 20px;
}
#PersDataDateB{
    font-family: "Inter-Regular";
    font-weight: 500;
    font-size: 14px;
    margin-top: 6px;
}
#PersDataPhoneBlock{
    margin-top: 28px;
    display: flex;
    align-items: center;
}
#PersDataPhoneContentBlock{
    display: flex;
    align-items: center;
}
.persDataListItemsBlock{
    margin-top: 5px;
}
.persDataGrayText{
    margin-top: 8px;
}
.persDataIcons{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 24px;
}
.persDataContentBlock{
    display: flex;
    margin-top: 30px;
    align-items: center;
}
.persDataContentBlock:last-child{
    padding-bottom: 25px;
}
#PersDataDontActiveText{
    font-family: "Inter-Bold";
    color:#DF7E7E;
}
#PersDataDontActiveBlock
{
    background:#DF7E7E;
    color: white;
    font-family: "Inter-Regular";
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 14px;   
}
  @media (min-width: 992px) {
    #PersData{
        width: 40%;
        position: initial;
        padding: 27px 0;
        padding-right: 40px;
        padding-left: 126px;
        padding-top: 27px;
        border-top: 1px solid #E4E6E8;
        border-right: 1px solid #E4E6E8;
        height: 100%;
        overflow: auto;
    }
    #NameBlock{
        padding: 25px 40px;
    }
    .persDataContentBlock:last-child{
        padding-bottom: 0;
    }
  }