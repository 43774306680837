@import 'src/App.scss';

#SPData{
    flex-grow: 1;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
}
#SPDataButtonBlock{
    margin: 0 15px;
    margin-top: 15px;
}
#SPDataDownloadIK{
    font-family: "Inter-Regular";
    padding: 15px 27px;
    background: $darkGreenSystem;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
}
#SPDataDownloadIK.Disable{
    background: #DBDBDB;
    color: white;
    cursor: default;
}
#SPDataDownloadIKText{
    font-size: 14px;
    margin-right: 20px;
    font-family: "Inter-Bold";
    font-weight: 700;
}
#SPDataDownloadIKIcon{
    width: 24px;
    height: 24px;
}
#SPDataHelpButtonBlock{
    display: flex;
    align-items: center;
}
.spDataHelpButton{
    background: #F5F5FA;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    color: #8083A3;
    font-family: "Inter-Bold";
    font-weight: 700;
    font-size: 14px;
    padding: 19.5px 27px;
    cursor: pointer;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 15px;
    margin-right: 13px;
}
.spDataHelpButton:last-child{
    margin-right: 0;
}
#SPDataSPBlock{
    border-left: 1px solid #E4E6E8;
    border-right: 1px solid #E4E6E8;
    border-radius: 12px;   
    padding: 19px 32px; 
    margin: 0 15px;
    margin-top: 25px;
    box-shadow: 0px 3px 4px #999ba840;  
}
#SPDataSPTitle{
    font-size: 20px;
    font-family: "Inter-Bold";
}
#SPDataSPContent{
    display: flex;
    flex-direction: column;
}
.spdataSPIcons{
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: $lightGreenSystem;
    padding: 9px;
    object-fit: cover;
    margin-right: 26px;
}
.spataSPItemBlock{
    display: flex;
    padding: 19px 0px;
    border-top: 1px solid #E4E6E8;
    align-items: center;
}
.spataSPItemBlock:first-child{
    border-top: none;
}
.spdataSPItemParametersBlock{
    flex-grow: 1;
    line-height: 21px;
}
.spdataSPItemParameterBlock{
    display: block;
    flex-grow: 1;
    margin-top: 10px;
}
.spdataSPItemParameterBlock:first-child{
    margin-top: 0;
}
.spdataGPBlock{
    display: flex;
    align-items: center;
}
.spdataCheckIcon{
    width: 15px;
    height: 15px;
    object-fit: cover;
    margin-right: 10px;
}
.multiplicityBlock{
    background: #f5f5fa;
    border-radius: 8px;
    padding: 7px 17px;
    min-width: 168px;
    max-height: 60px;
    box-sizing: border-box;
    margin-left: 5px;
}
.spdataChildrenServicesBlock{
    display: flex;
}
.spdataChildrenService{
    margin-right: 5px;
}
#SPDataDontActiveIKBlock{
    box-sizing: border-box;
    border-radius: 10px;
    background: #FFE8E8;
    padding: 25px 40px;
    display: flex;
    align-items: center;
    margin: 15px;
}
#SPDataDontActiveIKIcon{
    margin-right: 15px;
}
#SPDataDontActiveIKText{
    font-family: "Inter-Regular";
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
}
#SPDataDontActiveIKButton{
    border-radius: 10px;
    background: white;
    color: $greenSystem;
    border: 2px solid $greenSystem;
    font-family: "Inter-Bold";
    font-size: 14px;
    width: max-content;
    padding: 15px 20px;
    margin-left: 15px;
    cursor: pointer;
    transition: 0.3s;
}
#SPDataDontActiveIKButton:hover{
    background: $greenSystem;
    color: white;
    border: 2px solid $greenSystem;
}

@media (min-width: 992px) {
    #SPData{
        padding-left: 47px;
        padding-right: 135px;
        padding-top: 27px;
        border-top: 1px solid #E4E6E8;
    }
    #SPDataButtonBlock{
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #SPDataSPBlock{
        margin: 0;
        margin-top: 25px;
    }
    .spdataSPItemParameterBlock{
        display: flex;
        justify-content: space-between;
    }
    #SPDataDownloadIK, .spDataHelpButton{
        margin-top: 0;
        width: max-content;
        justify-content: initial;
    }

    #SPDataDontActiveIKBlock{
        margin: 0;
    }
    #SPDataDontActiveIKButton{
        margin: 0;
        margin-top: 15px;
    }
  }