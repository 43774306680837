@import 'src/App.scss';

#Header{
    height: 64px;
    padding-left: 126px;
    padding-right: 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#HeaderLogo{
    height: 35px;
    object-fit: cover;
}
#HeaderLogoOut{
    height: 32px;
    width: 32px;
    margin-left: 30px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
}
#HeaderLogoOut:hover{
    background: $lightGreenSystem;
}
#HeaderRightBlock{
    display: flex;
    align-items: center;
}
#HeaderRightPhone{
    color: #171721;
    font-weight: 600;
    font-size: 18px;
    font-family: "Inter-Regular";
}
#HeaderRightText{
    color: #8083A3;
    font-family: "Inter-Regular";
    font-size: 10px;
}
#HeaderMobile{
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
}
#HeaderMobileLogo{
    width: 176px;
    object-fit: cover;
}
.headerMobileIconBlock{
    width: 18px;
}
.headerMobileIcon{
    height: 18px;
    object-fit: cover;
}
.headerMobileIcon.hidden{
    display: none;
}