#LK{
    display: flex;
    flex-direction: column;
    height: 100%;
}
#LKContentBlock{
    position: relative;
    flex-grow: 1;
    display: flex;
}
#LKContent{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
}
.blackText{
    font-family: "Inter-Bold";
    font-size: 14px;
}
.grayText{
    font-weight: 500;
    font-size: 14px;
    color: #8083A3;
    font-family: "Inter-Regular";
}