$greenSystem:#0CAF89;
$darkGreenSystem:#13B08F;
$lightGreenSystem:#13b08f1f;
$redSystem:#FBB0B0;

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e9e9e9;
  border-radius: 7px;
}
::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 10px;
}
  ::-webkit-scrollbar-thumb:hover {
      background: #cfcfcf;
  }

a{
  text-decoration: none;
  color: inherit;
}

@font-face {
    font-family: "Inter-Regular";
    src: url("./fonts/Inter-Regular.ttf");
  }
  @font-face {
    font-family: "Inter-Bold";
    src: url("./fonts/Inter-Bold.ttf");
  }

html, body, #root, .App{
    width: 100%;
    height: 100%;
}

.inputCustom{
    outline: none;
    border: 1px solid #E4E6E8 ;
    font-family: "Inter-Bold";
    padding: 15px 20px;
    border-radius: 10px;
    transition: 0.3s;
}
.inputCustom:focus{
  border: 1px solid $darkGreenSystem ;
}
.inputCustom.inValid{
    border: 1px solid $redSystem;
}
.inputCustom.inValid::placeholder{
  color: $redSystem;
}
.inputCustom::placeholder{
    color:#8083A3;
    font-family: "Inter-Bold";
}

@media (max-width: 767px){
    .containerBlock {
      max-width: 540px;
    }  
  }
  
  @media (min-width: 768px){
    .containerBlock {
      max-width: 720px;	
    }
  }

  @media (max-width: 991px) {
  .pcBlock{
    display: none!important;
    }
    .mobileBlock{
      display: initial;
  }
}
  
  @media (min-width: 992px) {
      .inputDefault{
        width: 300px;
      }
    .containerBlock {
      max-width: 960px;
    }
    .pcBlock{
      display: initial;
      }
      .mobileBlock{
        display: none!important;
    }
  }
  
  @media (min-width: 1200px){
    .containerBlock {
      max-width: 1140px;
    }
  }