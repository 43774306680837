@import 'src/App.scss';

#Auth{
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    display: flex;
    padding: 30px;
}
#AuthBannerBlock{
    border-radius: 20px;
    background: linear-gradient(146.42deg, #9AC0FE 2.9%, #62D9B2 99.11%);
    height: calc(100% - 80px);
    width: calc(50% - 100px);
    padding: 40px 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
#AuthLogoWhite{
    width: 367px;
}
.authBannerText{
    color: white;
}
#authBannerDownTextPhone{
    font-family: "Inter-Regular";
    font-size: 18px;
    line-height: 22px;
}
#authBannerDownText{
    font-family: "Inter-Regular";
    font-size: 10px;
    line-height: 12px;
}
#AuthBannerMiddleTextTitle{
    font-family: "Inter-Bold";
    font-size: 30px;
    margin-bottom: 40px;
}
.authBannerMiddleText{
    font-family: "Inter-Regular";
    margin-bottom: 30px;
    font-size: 16px;
}
.authBannerMiddleText:last-child{
    margin-bottom: 0;
}
#AuthFormBlock{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 73px;
}
#AuthFormBlock{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
#AuthForm{
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.authFormBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.authFormInput{
    margin-top: 22px;
}
.authFormInput:first-child{
    margin-top: 0px;
}
.authFormInput:last-child{
    position: relative;
}
#AuthLogo{
    width: 290px;
    height: 53.66px;
    object-fit: contain;
}
#AuthLogoText{
    margin-top: 32px;
    font-family: "Inter-Bold";
    color: #171721;
    font-size: 28px;
}
#AuthButton{
    background: #54A78D;
    border-radius: 10px;
    color: white;
    font-family: "Inter-Bold";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 20px;
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.9;
}
#AuthButton:hover{
    opacity: 1;
}

#AuthPhoneMobileBlock{
    display: flex;
    margin-top: 16px;
    color: #8083A380;
    flex-direction: column;
    align-items: center;
}
#AuthPhoneMobilePhone{
    font-family: "Inter-Bold";
    font-size: 16px;   
}
#AuthPhoneMobileText{
    font-family: "Inter-Regular";
    font-weight: 600;
    font-size: 8px;
}
#AuthNotFoundIKMobileBlock{
    color: #EE8383;
    margin-top: 20px;
    font-family: "Inter-Bold";
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
} 
#AuthNotFoundIKMobileBlock.hidden{
    opacity: 0;
} 
#AuthNotFoundIKMobile{
    position: absolute;
} 
#AuthNotFoundIK{
    border: 1px solid #E4E6E8;
    border-radius: 10px;
    padding: 23px 18px;
    margin-top: 30px;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 30px;
}   
#AuthNotFoundIK.hidden{
    display: none;
}  
#AuthNotFoundIKHeaderBlock{
    display: flex;
}
#AuthNotFoundIKHeaderCrossIcon{
    width: 18px;
    margin-right: 17px;
 }   
 #AuthNotFoundIKHeaderText{
    font-family: "Inter-Bold";
    color: #EE8383;
    font-size: 18px;
 }
 #AuthNotFoundIKPhone{
    font-family: "Inter-Bold";
    font-size: 16px;
 }
 .authNotFoundIKTextBlock{
     margin-left: 35px;
     margin-top: 10px;
 }

  @media (max-width: 768px){

    #AuthLogoText{
        font-size: 22px;
    }

    .authFormInput, #AuthButton{
        width: calc(100% - 40px);
    }
  }
  
  @media (min-width: 992px) {

    .authFormInput, #AuthButton{
        width: 380px;
    }
    #AuthNotFoundIK{
        width: 420px;
    }
  }
  @media (max-height: 899px) {

    #AuthNotFoundIK{
        display: none;
    }
    #AuthNotFoundIKMobileBlock{
        display: flex!important;

    }
}
  @media (min-height: 900px) {

    #AuthNotFoundIK{
        display: block;
    }
}